import React from 'react';
import unityIcon from './gamepad-icon.png';
import arkuniAvatar from './arkuni-avatar.png';
import { App } from "shared";

const primaryBackgroundColor = "#000";
const secondaryBackgroundColor = "#111";

export const UnityApp: React.FC = () =>
{
	return (
		<App
			title="Unity SOS"
			topic="Unity"
			topicIconUrl={unityIcon}
			arkuniAvatarUrl={arkuniAvatar}
			email="unity@arkuni.com"
			highlights={[
				'Getting started with Unity and C#',
				'Using native functionality like Shader Graph, UGUI, and UIElements', // Entity-Component-System
				'Ways to do scripting, architecture, serialization, state management, and code sharing', // Testing
				'Working with popular assets like DOTween, NodeCanvas, PoolManager, and Odin Inspector', // Bolt? 
				'Help with a general or specific problems',
				'A shoulder to cry on while sharing your developer frustrations'
			]}
			primaryColor={{
				main: "#FFF",
				dark: "#CCC",
				light: "#FFF",
				contrastText: "#000"
			}}
			primaryBackgroundColor={primaryBackgroundColor}
			secondaryBackgroundColor={secondaryBackgroundColor}
		/>
	);
}
